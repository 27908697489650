import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosRequestConfig } from 'axios';
import { ApiEndpoints, ParamsType } from '../utils/apiUtils';
import { useCognitoAuth } from './CognitoAuthContext';


export function useCognitoAuthHooks() {
    const {
        isLoading,
        isAuthenticated,
        getIdToken,
        userEmail,
        logout,
    } = useCognitoAuth();

    // Cognito deployments will use SAML for login, but this makes it behave like the Auth0 flow:
    const isEmailVerified = async () => { return true; }
    return {
        isLoading,
        isAuthenticated,
        logout,
        getIdToken,
        isEmailVerified,
        userEmail,
    }
}

export function useAuth0AuthHooks() {
    const {
        isLoading,
        isAuthenticated,
        getIdTokenClaims,
        user,
        logout
    } = useAuth0();

    const isEmailVerified = async () => {
        return await getIdTokenClaims().then((claims) => claims ? claims.email_verified : false);
    }

    const getIdToken = async () => {
        return await getIdTokenClaims().then((claims) => claims ? claims.__raw : null);
    }
    return {
        isLoading,
        isAuthenticated,
        getIdToken,
        isEmailVerified,
        userEmail: user ? user.email : '',
        logout: () => logout({ logoutParams: { returnTo: window.location.origin } }),
    }
}

export function useAuthTokenAndAccessApi() {
    const useCognito = process.env.REACT_APP_USE_COGNITO === 'TRUE';
    const authHook = useCognito ? useCognitoAuthHooks : useAuth0AuthHooks;

    const {
        isLoading,
        isAuthenticated,
        isEmailVerified,
        getIdToken,
        userEmail,
        logout,
    } = authHook();

    const apiBaseUrl = process.env.REACT_APP_API_BASEURL as string;

    const fetchData = async (
        endpoint: ApiEndpoints,
        params: ParamsType = {},
        axiosOptions: Partial<AxiosRequestConfig> = {}
    ) => {
        const token = await getIdToken();
        if (!token) { throw new Error('Token is not available'); }
        try {
            const config = {
                headers: {
                    Authorization: "Bearer " + token,
                },
                baseURL: apiBaseUrl,
                params: params,
                ...axiosOptions, // other options like responseType, onDownloadProgress
            };
            const response = await axios.get(endpoint, config);
            return response;
        } catch (err) {
            console.error('Error fetching data:', err);
            throw err;
        }
    };

    const sendData = async (
        endpoint: ApiEndpoints,
        data: any = {},
        method: 'POST' | 'PUT',
        params: ParamsType = {},
    ) => {
        const token = await getIdToken();
        if (!token) throw new Error('Token is not available');
        try {
            const config: AxiosRequestConfig = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                baseURL: apiBaseUrl,
                params: params,
            };

            const response = await axios({
                url: endpoint,
                method: method,
                data: data,
                ...config
            });
            return response;
        } catch (err) {
            console.error(`Error ${method.toLowerCase()}ing data:`, err);
            throw err;
        }
    };

    const postData = (endpoint: ApiEndpoints, data: any = {}) =>
        sendData(endpoint, data, 'POST');
    const putData = (endpoint: ApiEndpoints, data: any = {}, params: ParamsType = {}) =>
        sendData(endpoint, data, 'PUT', params);

    return {
        isLoading,
        isAuthenticated,
        userEmail,
        isVerified: isEmailVerified,
        logout,
        fetchData,
        postData,
        putData,
    };
}
