import { Amplify } from 'aws-amplify';
import { fetchAuthSession, getCurrentUser, signInWithRedirect, signOut, } from 'aws-amplify/auth';
import React, { createContext, useContext, useEffect, useState } from 'react';


interface CognitoAuthContextProps {
    isLoading: boolean;
    isAuthenticated: boolean;
    logout: () => void;
    getIdToken: () => Promise<string | null>;
    userEmail: string;
    loginWithRedirectProvider: (provider: string) => void;
}

const redirectUrl = window.location.origin
const domain = process.env.REACT_APP_AUTH_COGNITO_DOMAIN as string
const clientId = process.env.REACT_APP_AUTH_COGNITO_CLIENT_ID as string
const userPoolId = process.env.REACT_APP_AUTH_COGNITO_USER_POOL_ID as string



Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId: clientId,
            userPoolId: userPoolId,
            loginWith: {
                oauth: {
                    domain: domain,
                    scopes: ['email', 'openid', 'profile'],
                    redirectSignIn: [redirectUrl],
                    redirectSignOut: [redirectUrl],
                    responseType: 'code',
                },
            }
        },
    }
});


const CognitoAuthContext = createContext<CognitoAuthContextProps | undefined>(undefined);

export const useCognitoAuth = () => {
    const context = useContext(CognitoAuthContext);
    if (!context) {
        throw new Error("useCognitoAuth must be used within a CognitoAuthProvider");
    }
    return context;
};

interface CognitoAuthProviderProps {
    children: React.ReactNode;
}


// CognitoAuthProvider component that provides the context value
export const CognitoAuthProvider: React.FC<CognitoAuthProviderProps> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const checkAuth = async () => {
        try {
            const session = await fetchAuthSession();
            if (session && session.tokens?.idToken) {
                setIsAuthenticated(true);
                const user = await getCurrentUser();
                setUserEmail(user?.username || '');
            } else {
                setIsAuthenticated(false);
            }
        } catch (error) {
            console.error('Check auth error:', error);
            setIsAuthenticated(false);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        checkAuth();
    }, []);

    const loginWithRedirectProvider = async (provider: string) => {
        try {
            await signInWithRedirect({ provider: { custom: provider } });
        } catch (error) {
            console.error('SAML sign-in error:', error);
        }
    };

    const logout = async () => {
        await signOut();
        setIsAuthenticated(false);
    };

    const getIdToken = async () => {
        try {
            const token = await fetchAuthSession().then((session) => session ? session.tokens?.idToken?.toString() : null);
            if (!token) {
                return null;
            }
            return token;
        } catch (error) {
            return null;
        }
    };

    return (
        <CognitoAuthContext.Provider value={{
            isLoading,
            isAuthenticated,
            loginWithRedirectProvider,
            logout,
            getIdToken,
            userEmail
        }}>
            {children}
        </CognitoAuthContext.Provider>
    );
};
