import { Card, Typography } from '@mui/material';
import { portalColorScheme } from '../../utils/colorScheme';

export default function ScaleBar({ zoomLevel, voxelSize }:
    { zoomLevel: number, voxelSize: number }) {

    const lengthInMm = () => {
        const scaledVoxelSize = voxelSize / zoomLevel
        if (scaledVoxelSize >= 1e0) return 50
        if (scaledVoxelSize >= 5e-1) return 10
        if (scaledVoxelSize >= 1e-1) return 5
        if (scaledVoxelSize >= 5e-2) return 2
        if (scaledVoxelSize >= 1e-2) return 1
        if (scaledVoxelSize >= 5e-3) return 0.5
        if (scaledVoxelSize >= 1e-3) return 0.2
        return 0.1
    }
    const desiredPixelLength = lengthInMm() / voxelSize;
    const scaleBarLengthPixels = desiredPixelLength * zoomLevel;



    return (
        <Card
            sx={{
                position: 'absolute',
                bottom: 10,
                right: 10,
                display: 'flex',
                alignItems: 'center',
                padding: '8px',
                opacity: 0.9,
                width: `auto`,
            }}
        >
            <Typography
                variant='body2'
                sx={{
                    color: portalColorScheme.white,
                    marginRight: '8px',
                }}
            >
                {lengthInMm() < 10 ? lengthInMm().toFixed(1) : lengthInMm().toFixed(0)} mm
            </Typography>
            <div
                style={{
                    position: 'relative',
                    backgroundColor: portalColorScheme.white,
                    height: 2,
                    width: scaleBarLengthPixels,
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '-5px',
                        left: 0,
                        height: '10px',
                        width: '2px',
                        backgroundColor: portalColorScheme.white,
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '-5px',
                        right: 0,
                        height: '10px',
                        width: '2px',
                        backgroundColor: portalColorScheme.white,
                    }}
                />
            </div>
        </Card >
    );
}
