import {
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineController,
    LineElement,
    LinearScale,
    LogarithmicScale,
    PointElement,
    Title,
    Tooltip
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useEffect, useRef } from 'react';
import { Chart, } from 'react-chartjs-2';
import { LineChartData } from '../../types';
import { colorScheme } from '../../utils/colorScheme';



// Gotcha: got to register these pieces of ChartJS before using them
ChartJS.register(LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    Title,
    CategoryScale,
    BarElement,
    LineController,
    BarController,
    LogarithmicScale,
    zoomPlugin
);

export default function OneChartLine(
    {
        chartData,
        idClicked,
        metricDisplayName,
        metricDisplayUnit,
        xAxisTitle,
        showAvg,
        showMin,
        showMax,
        resetChartZoom
    }: {
        chartData: LineChartData[],
        idClicked: (id: number) => void
        metricDisplayName: string,
        metricDisplayUnit: string,
        xAxisTitle: string,
        showAvg: boolean,
        showMin: boolean,
        showMax: boolean,
        resetChartZoom: boolean
    }
) {
    const chartRef = useRef<any>()
    const resetZoom = () => {
        if (chartRef && chartRef.current) {
            chartRef.current.resetZoom();
        }
    };
    const handleIndexClick = (index: number) => {
        idClicked(chartData[index].id)
    }

    useEffect(() => {
        resetZoom();
    }, [resetChartZoom]);

    let pointRadius = 3;
    if (chartData.length < 10) {
        pointRadius = 5;
    }

    const options: any = {
        scales: {
            y: {
                type: 'linear',
                title: {
                    display: true, text: metricDisplayName.concat(" (", metricDisplayUnit, ")"), color: colorScheme.chartText,
                },
                grid: {
                    lineWidth: 0.5, color: colorScheme.chartText,
                },
                ticks: {
                    color: colorScheme.chartText,
                },
                beginAtZero: false
            },
            x: {
                ticks: {
                    color: colorScheme.chartText,
                },
                title: {
                    display: true, text: xAxisTitle, color: colorScheme.chartText,
                },
            },
        },
        layout: { padding: 10 },
        plugins: {
            title: { display: false, text: metricDisplayName.concat(" ", metricDisplayUnit), color: colorScheme.chartText },
            legend: {
                display: false,
                labels: {
                    color: colorScheme.chartText,
                }
            },
            tooltip: {
                callbacks: {
                    title: (context: any) => {
                        return ` ${chartData[context[0].dataIndex].tooltip}`;
                    },
                    afterTitle: (context: any) => {
                        return ` ${context[0].label}`;
                    },
                    label: (context: any) => {
                        const dataPoint = context.dataset.data[context.dataIndex];
                        if (context.dataset.type === 'line') {
                            return ` ${context.dataset.label}: ${Number(dataPoint).toPrecision(4)}`;
                        }
                        return ` ${context.dataset.label}: ${Number(dataPoint[0]).toPrecision(4)} to ${Number(dataPoint[1]).toPrecision(4)}`;
                    },
                },
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'xy',
                    modifierKey: 'shift',
                },
                zoom: {
                    drag: {
                        enabled: true
                    },
                    mode: 'xy',
                },
                resetZoom: {
                    enabled: true,
                    text: 'Reset Zoom', // Customize the text of the reset zoom button
                }
            }
        },
        onClick: (event: any, elements: any) => {
            if (elements.length > 0) {
                const clickedDataIndex = elements[0].index;
                handleIndexClick(clickedDataIndex);
            }
        },
        animation: {
            duration: 100,
        },
        maintainAspectRatio: false,
    };

    const labels = chartData.map((e) => e.xLabel);

    const dataAverage = {
        type: 'line' as const,
        label: 'Average',
        pointBackgroundColor: colorScheme.average,
        borderColor: colorScheme.average,
        borderWidth: 2,
        backgroundColor: colorScheme.average,
        showLine: false,
        data: chartData.map((e) => e.average),
        pointRadius: pointRadius
    }
    const dataMin = {
        type: 'line' as const,
        label: 'Min',
        borderColor: colorScheme.min,
        pointBackgroundColor: colorScheme.min,
        backgroundColor: colorScheme.min,
        borderWidth: 2,
        showLine: false,
        data: chartData.map((e) => e.min),
        pointRadius: pointRadius + 3,
        pointStyle: 'line'
    }
    const dataMax = {
        type: 'line' as const,
        label: 'Max',
        pointBackgroundColor: colorScheme.max,
        borderColor: colorScheme.max,
        backgroundColor: colorScheme.max,
        borderWidth: 2,
        showLine: false,
        data: chartData.map((e) => e.max),
        pointRadius: pointRadius + 3,
        pointStyle: 'line'
    }

    let datasets = [] as any;
    if (showAvg) {
        datasets.push(dataAverage);
    }
    if (showMin) {
        datasets.push(dataMin);
    }
    if (showMax) {
        datasets.push(dataMax);
    }

    const data = {
        labels,
        datasets: datasets
    };

    return <Chart type='line' data={data as any} options={options} ref={chartRef} />
}
