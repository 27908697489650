import { Autocomplete, FormControl, TextField } from '@mui/material';
import { ScanList } from '../types';
import { formatIsoDate } from '../utils';


export default function ScanDropdownSelector(
    { scanList, selectedScanId, onScanSelectionChange }:
        { scanList: ScanList[], selectedScanId: string | undefined, onScanSelectionChange: (selection: string) => void }
) {
    const autoCompleteChange = (event: any, value: any) => {
        onScanSelectionChange(value.split(':')[0])
    }
    const formatDropdownOption = (e: ScanList) => `${e.scan_id}: ${e.request_name}, ${e.cell_sn}, ${formatIsoDate(e.post_processing_completed)}`
    const dropdownOptions = scanList.map((e) => formatDropdownOption(e))
    const autocompleteValue = (selectedScanId: string | undefined) => {
        const searchResult = dropdownOptions.find((e) => e.split(':')[0] === selectedScanId)
        return searchResult || ""
    }
    if (scanList.length > 0) {
        return <FormControl variant="standard" sx={{ marginLeft: "14pt", width: 600 }}>
            <Autocomplete
                disableClearable
                size="small"
                value={autocompleteValue(selectedScanId)}
                options={dropdownOptions}
                renderInput={(params) => <TextField {...params} />}
                onChange={(e, value) => { autoCompleteChange(e, value) }}
            />
        </FormControl>
    }
    return null
}
