import { Box, Button, Link, Typography } from '@mui/material';
import { useCognitoAuth } from '../auth/CognitoAuthContext';

export default function LoginCustomerP() {
    const { loginWithRedirectProvider } = useCognitoAuth();
    const customerSamlIdp = process.env.REACT_APP_CUSTOMER_SAML_IDP as string;
    const glimpseAdminSamlIdp = process.env.REACT_APP_ADMIN_SAML_IDP as string;

    const loginWithCustomerSaml = (e: any) => {
        e.preventDefault();
        loginWithRedirectProvider(customerSamlIdp);
    }

    const loginWithGlimpseAdminSaml = (e: any) => {
        e.preventDefault();
        loginWithRedirectProvider(glimpseAdminSamlIdp);
    }

    return <Box sx={{ maxWidth: 400, mx: 'auto', p: 3, textAlign: 'center' }}>
        <Button
            type="submit"
            variant="outlined"
            color="primary"
            sx={{ mt: 2 }}
            onClick={loginWithCustomerSaml}
        >
            Log In with Okta
        </Button>
        <Typography variant="body1" sx={{ mt: 2 }}>
            You will be redirected to the Panasonic Okta login page.
        </Typography>
        <br />
        <Typography variant="body2" sx={{ mt: 2 }}>
            Glimpse Admin users log in <Link onClick={loginWithGlimpseAdminSaml} href="#">here</Link>.
        </Typography>
    </Box>
}
