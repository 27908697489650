import { Box, Typography } from '@mui/material';
import 'katex/dist/katex.min.css';
import { useEffect, useState } from 'react';
import NavBar from './NavBar';
import RightContent from './RightContent';
import SearchBar, { Question } from './SearchBar';


import { UserAccessState } from '../../types';
import { appTopNavBarHeight } from '../../utils';
import './styles.css';


interface Category {
  name: string;
  questions: Question[];
}

export default function FaqPage({ userAccessState }: { userAccessState: UserAccessState }

) {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [expandedQuestions, setExpandedQuestions] = useState<{ [key: string]: boolean }>({});
  const [searchText, setSearchText] = useState('');
  const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([]);
  const [searchResults, setSearchResults] = useState(false);

  useEffect(() => {
    let parsedCategories: Category[] = [];
    fetch("faq_content.md")
      .then(res => res.text())
      .then(text => {
        text = text.replace(/<!--[\s\S]*?-->/g, '');
        parsedCategories = parseCategories(text);
        setCategories(parsedCategories);
        if (parsedCategories.length > 0) {
          setSelectedCategory(parsedCategories[0].name);
        }
      }).then(() => {
        if ([UserAccessState.GLIMPSE_INTERNAL_WRITE, UserAccessState.CUSTOMER_OPERATOR].includes(userAccessState)) {
          fetch("api_docs.md")
            .then(res => res.text())
            .then(text => {
              text = text.replace(/<!--[\s\S]*?-->/g, '');
              const apiParsedCategories = parseCategories(text);
              setCategories([...parsedCategories, ...apiParsedCategories]);
              if (parsedCategories.length > 0) {
                setSelectedCategory(parsedCategories[0].name);
              }
            })
        }
      });
  }, [userAccessState]);

  // find categories and questions in the markdown file
  const parseCategories = (text: string): Category[] => {
    const lines = text.split('\n');
    const categories: Category[] = [];
    let currentCategory: Category | null = null as Category | null;
    let currentQuestion: Question | null = null;

    lines.forEach(line => {
      if (line.startsWith('## ')) {
        if (currentCategory) {
          if (currentQuestion) {
            currentCategory.questions.push(currentQuestion);
            currentQuestion = null;
          }
          categories.push(currentCategory);
        }
        currentCategory = { name: line.replace('## ', ''), questions: [] };

      } else if (line.startsWith('### ')) {
        if (currentCategory && currentQuestion) {
          currentCategory.questions.push(currentQuestion);
        }
        currentQuestion = { question: line.replace('### ', ''), answer: '' };

      }
      else if (currentQuestion) {
        currentQuestion.answer += line + '\n';
      }
    });
    if (currentQuestion && currentCategory) {
      currentCategory.questions.push(currentQuestion);
    }

    if (currentCategory) {
      categories.push(currentCategory);
    }

    return categories;
  };

  //open and close the question
  const toggleQuestion = (question: string) => {
    setExpandedQuestions(prevState => ({
      ...prevState,
      [question]: !prevState[question],
    }));
  };

  //search for a question
  const handleSearchDropdown = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setSearchText(text);
    if (text) {
      const matchQuestion = categories.flatMap(category => category.questions.filter(question => question.question.toLowerCase().includes(text.toLowerCase())));
      setFilteredQuestions(matchQuestion);
      setSearchResults(true);
    }
    else {
      setSearchResults(false);
    }
  }

  // open question when selected from search
  const handleSelectQuestion = (selectedQuestion: Question) => {
    const category = categories.find(category => category.questions.some(question => question.question === selectedQuestion.question));
    if (category) {
      setSelectedCategory(category.name);
      setExpandedQuestions(prevState => ({
        ...prevState,
        [selectedQuestion.question]: true,
      }));
      setSearchResults(false);
      setSearchText('');
    }
  }


  return (
    <Box sx={{ display: "flex", flexDirection: "column", padding: "30px", margin: "auto", height: "90vh", overflow: "hidden" }}>
      {/* Page Title */}
      <Typography
        variant="h4"
        component="h1"
        sx={{
          marginBottom: "20px",
          position: "sticky",
          top: 0,
          zIndex: 1000,
          padding: "16px 0",
          fontWeight: "800"
        }}
      >
        Frequently Asked Questions
      </Typography>

      {/* Main Content */}
      <Box sx={{ display: "flex", flexGrow: 1, height: `calc(100vh - ${appTopNavBarHeight}px)` }}>
        <Box sx={{ display: "flex", flexDirection: "column", flexShrink: 0 }}>
          <SearchBar
            handleSearchDropdown={handleSearchDropdown}
            searchResults={searchResults}
            handleSelectQuestion={handleSelectQuestion}
            searchText={searchText}
            filteredQuestions={filteredQuestions}
          />
          <NavBar
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            categories={categories}
          />
        </Box>
        <RightContent selectedCategory={selectedCategory}
          categories={categories}
          expandedQuestions={expandedQuestions}
          toggleQuestion={toggleQuestion} />
      </Box>
    </Box >

  );
}
