import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import LoginAuth0 from '../components/LoginAuth0';
import LoginCustomerP from '../components/LoginAuthCustomerP';

export default function LoginPage() {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Typography variant="h5" component="div" color='#FFF' sx={{ pt: 3 }}>
                Welcome to the Glimpse Portal<sup style={{ fontSize: 11 }}>TM</sup>
            </Typography>
            {process.env.REACT_APP_USE_COGNITO === 'TRUE' ? <LoginCustomerP /> : <LoginAuth0 />}
        </Grid>
    );
}
