import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';


export default function BasicLoadingIndicator(
    { message }: { message: string }
) {
    return <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ pt: 8 }}
    >
        <Typography variant="body1" >
            {message}
        </Typography>
        <CircularProgress />
    </Grid>


}
