import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { LineChartData } from '../../types';
import { Granularity } from './page';


export default function DownloadData(
    {
        chartData,
        metric_name,
        metricDisplayUnit,
        granularity,
    }: {
        chartData: LineChartData[],
        metric_name: string;
        granularity: Granularity.SCAN | Granularity.REQUEST;
        metricDisplayUnit: string;
    }
) {

    const CSVDownloader = ({ chartData, metric_name, granularity }: {
        chartData: LineChartData[];
        metric_name: string;
        granularity: Granularity.SCAN | Granularity.REQUEST;
    }) => {
        const [csvData, setCsvData] = useState<string>('');

        useEffect(() => {
            if (chartData.length > 0) {
                prepareCSVData(chartData);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [chartData, granularity]);

        const prepareCSVData = (data: LineChartData[]) => {
            let csvHeader = granularity === Granularity.SCAN ? 'Serial Number' : 'Batch Name';
            let csvProducer = granularity === Granularity.SCAN ? 'Producer + Cell Model' : 'Producer';
            let csvContent = `${csvHeader},${csvProducer},Average,Min Value,Max Value, Unit\n`;

            data.forEach((item) => {
                csvContent += `${item.xLabel},${item.tooltip},${item.average.toFixed(3)},${item.min.toFixed(3)},${item.max.toFixed(3)}, ${metricDisplayUnit}\n`;
            });

            setCsvData(csvContent);
        };

        const handleDownloadCSV = () => {
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            const granularityName = granularity === Granularity.SCAN ? 'Scan' : 'Batch';
            link.href = url;
            link.download = `${metric_name} ${granularityName} Data.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        };

        return (
            <Tooltip placement='left' title={
                <Typography variant='body2'>
                    Download this data.
                </Typography>
            }>
                <DownloadForOfflineIcon sx={{ mt: 1, ml: 1, position: 'absolute', right: 90 }}
                    onClick={handleDownloadCSV} />

            </Tooltip>
        );
    };
    return <CSVDownloader chartData={chartData} metric_name={metric_name} granularity={granularity} />;

}
