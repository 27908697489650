import useUrlState from "@ahooksjs/use-url-state";
import { Box, Fade, Stack } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from "react";
import { useAuthTokenAndAccessApi } from "../../auth/authHooks";
import ScanDropdownSelector from "../../components/ScanDropdownSelector";
import { ReactComponent as NikonLogo } from "../../components/SliceViewerPage/NikonLogo.svg";
import SliceDisplayManager from "../../components/SliceViewerPage/SliceDisplayManager";
import { MeasurementDimensions, ScanAxes, ScanList, ScanMetadata, SliceRow } from "../../types";
import { ApiEndpoints, ParamsType } from "../../utils/apiUtils";
import CustomerScanContextData from "./CustomerScanContextData";



export default function SlicesPage(
    { scanList, isDemoMode, availableMetrics }: {
        scanList: ScanList[], isDemoMode: boolean, availableMetrics: MeasurementDimensions[]
    }
) {
    const { fetchData } = useAuthTokenAndAccessApi();
    const [urlStore, setUrlStore] = useUrlState({ selectedScanId: undefined as number | undefined })
    const [fadeIn, setFadeIn] = useState(false)
    const [scanAxes, setScanAxes] = useState(ScanAxes.CYL)
    const [sliceList, setSliceList] = useState<SliceRow[]>([] as SliceRow[])
    const [scanMetadata, setScanMetadata] = useState({} as ScanMetadata[])
    const [isCustomerScanContextOpen, setIsCustomerScanContextOpen] = useState(false);

    const scanIdList = scanList.map((scan) => scan.scan_id)
    const containerRef = useRef<HTMLDivElement>(null); // Ref for the container

    useEffect(() => {
        if (scanIdList.includes(parseInt(urlStore.selectedScanId))) {
            getSliceList(parseInt(urlStore.selectedScanId))
            getScanMetaData(isDemoMode, parseInt(urlStore.selectedScanId))
        }
        if (urlStore.selectedScanId === undefined) {
            setUrlStore({ selectedScanId: "" })
        }
        setFadeIn(true)
        return () => {
            setFadeIn(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDemoMode])


    const handleScanSelectionChange = (selection: string) => {
        setSliceList([] as SliceRow[])
        const new_scan_id = parseInt(selection)
        setUrlStore({ selectedScanId: new_scan_id.toString() })
        getSliceList(new_scan_id)
        getScanMetaData(isDemoMode, new_scan_id)
    }

    const getSliceList = async (scanId: number) => {
        const params = { scan_id: scanId } as ParamsType
        const endpoint = isDemoMode ? ApiEndpoints.DEMO_SLICE_LIST : ApiEndpoints.SLICE_LIST
        const responseSliceList = await fetchData(endpoint, params);
        try {
            if (responseSliceList.data.length > 0) {
                const sliceListRaw = responseSliceList.data as SliceRow[]
                setScanAxes(responseSliceList.data[0]['axes'] as ScanAxes || null)
                setSliceList(
                    sliceListRaw.map((slice: SliceRow) => {
                        // A "decimal" from marshmallow is a string, so we convert to a float here
                        return { ...slice, position: Number(parseFloat(slice.position.toString()).toFixed(2)) }
                    }))
            }
            else {
                throw new Error("No slices found for this scan")
            }
        }
        catch (error: any) {
            console.error(error)
        }
    }

    const getScanMetaData = async (isDemoMode: boolean, scanId: number) => {
        setScanMetadata([])
        let params = { scan_id: scanId } as ParamsType
        if (isDemoMode) params = { ...params, is_demo: true }
        const responseScanMetadata = await fetchData(ApiEndpoints.SCAN_METADATA, params);
        const scanMetadata = responseScanMetadata.data as ScanMetadata[];
        if (scanMetadata && scanMetadata.length > 0) setScanMetadata(scanMetadata)
        else setIsCustomerScanContextOpen(false)
    }


    const toggleScanContext = () => {
        setIsCustomerScanContextOpen(!isCustomerScanContextOpen);
    }

    const showNikonLogo = scanList.find((scan: ScanList) => scan.scan_id === parseInt(urlStore.selectedScanId))?.scanner_vendor === "Nikon" ? true : false
    return (
        <Box ref={containerRef} sx={{ marginRight: isCustomerScanContextOpen ? '20%' : '0' }}>
            <Fade in={fadeIn}>
                <Stack direction={"row"} spacing={2} sx={{ pt: "14pt", pb: "14pt", justifyContent: "space-between", maxWidth: 1300 }}>

                    <Box>
                        <Typography variant="h5" >
                            Viewing Scan:
                            <ScanDropdownSelector
                                scanList={scanList}
                                selectedScanId={urlStore.selectedScanId}
                                onScanSelectionChange={handleScanSelectionChange}
                            />
                        </Typography>
                    </Box>
                    <Typography variant="h5" sx={{ pr: 1 }} >
                        <Fade in={showNikonLogo}>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <NikonLogo style={{
                                    height: '39px',
                                    width: '39px',
                                    padding: '0px',
                                    margin: '0px',
                                }} />
                                <Typography variant='body2' width={130} sx={{ pl: 1 }}>
                                    Scanned with Nikon Equipment.
                                </Typography>
                            </Box>
                        </Fade>
                    </Typography>
                </Stack>
            </Fade>
            <CustomerScanContextData
                isCustomerScanContextOpen={isCustomerScanContextOpen}
                toggleScanContext={toggleScanContext}
                scanMetadata={scanMetadata}
            />

            {scanIdList.includes(parseInt(urlStore.selectedScanId)) && sliceList.length > 0 ?
                <SliceDisplayManager
                    scanData={scanList.filter((scan: ScanList) => scan.scan_id === parseInt(urlStore.selectedScanId))[0]}
                    sliceList={sliceList}
                    scanAxes={scanAxes}
                    isDemoMode={isDemoMode}
                    availableMetrics={availableMetrics}
                    scanMetadata={scanMetadata}
                    toggleScanContext={toggleScanContext}
                /> : null
            }
        </Box>
    );
}
