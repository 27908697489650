import { Box } from '@mui/material';
import { useState } from 'react';
import { UserAccessState } from '../../types';
import { appTopNavBarHeight } from '../../utils';
import LogOfScans from './LogOfScans';
import OperatorNav, { OperatorPageOptions } from './OperatorNav';
import SerialNumberRenamingTool from './SerialNumberRenamingTool';


export default function OperatorPage(
  { userAccessState }: { userAccessState: UserAccessState }
) {
  const [operatorPageType, setOperatorPageType] = useState(OperatorPageOptions.LogOfScans);
  return (
    <>
      <Box sx={{ display: "flex", flexGrow: 1, height: `calc(100vh - ${appTopNavBarHeight}px)` }}>
        <OperatorNav
          operatorPageType={operatorPageType}
          setOperatorPageType={setOperatorPageType}
          userAccessState={userAccessState}
        />
        <Box sx={{
          flexGrow: 1,
          borderLeft: "1px solid #ccc",
          height: `calc(100vh - ${appTopNavBarHeight}px)`,
          overflow: 'auto'
        }}>
          {operatorPageType === OperatorPageOptions.LogOfScans && <LogOfScans userAccessState={userAccessState} />}
          {operatorPageType === OperatorPageOptions.SerialNumberRenamingTool && <SerialNumberRenamingTool />}
        </Box>
      </Box >
    </>
  );
}
