import DownloadIcon from '@mui/icons-material/Download';
import { Box, Card, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useRef } from 'react';
import { AnnotationShapeList, ScanList, SliceOrientation } from '../../types';
import AnnotationLayer from './AnnotationLayer';

export default function DownloadButtons(
    { scanData, sliceOrientation, visibleImage, slicePosition,
        imageDimensions,
        imageFilterCss, annotationList,
    }: {
        scanData: ScanList,
        sliceOrientation: SliceOrientation
        visibleImage: string,
        slicePosition: number,
        imageDimensions: { width: number; height: number },
        imageFilterCss: () => string,
        annotationList: AnnotationShapeList[],
    }
) {

    const nameOrientation = (sliceOrientation: SliceOrientation) => {
        switch (sliceOrientation) {
            case SliceOrientation.AXIAL:
                return "_angle="
            case SliceOrientation.RADIAL:
                return "_z="
            case SliceOrientation.XY:
                return "_z="
            case SliceOrientation.YZ:
                return "_x="
            case SliceOrientation.XZ:
                return "_y="
            default:
                return ""
        }
    }

    const svgRef = useRef<SVGSVGElement>(null);

    const downloadImage = () => {
        if (!visibleImage) return;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            console.error('Failed to get canvas context');
            return;
        }

        canvas.width = imageDimensions.width;
        canvas.height = imageDimensions.height;

        const img = new Image();
        img.src = visibleImage;
        img.onload = () => {
            ctx.filter = imageFilterCss();
            ctx.drawImage(img, 0, 0, imageDimensions.width, imageDimensions.height);
            ctx.filter = 'none';

            if (svgRef.current) {
                const svgData = new XMLSerializer().serializeToString(svgRef.current);
                const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
                const url = URL.createObjectURL(svgBlob);
                const svgImage = new Image();
                svgImage.src = url;
                svgImage.onload = () => {
                    ctx.drawImage(svgImage, 0, 0, imageDimensions.width, imageDimensions.height);
                    URL.revokeObjectURL(url);

                    // blob defaults to png. If you want to download as avif, you need to specify the type
                    // Note: this results in downloading the converted AVIF->PNG (not original png from the pipeline)
                    canvas.toBlob((blob) => {
                        if (blob) {
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(blob);
                            let extension = '.png';
                            link.download = `${scanData.scan_id}` +
                                `_${scanData.cell_sn}` +
                                (nameOrientation(sliceOrientation)) +
                                `${slicePosition}` +
                                (sliceOrientation !== SliceOrientation.AXIAL ? "mm" : "deg") +
                                extension;
                            link.click();
                        } else {
                            console.error('Failed to create blob');
                        }
                        //just in case for old browsers/computers
                    }, 'image/png');
                };
            }
        };
    };

    return <Box
        top={0}
        right={0}
        display="flex"
        sx={{
            zIndex: 10,
            position: "absolute",
            mr: 1,
            mb: 1,
        }}>
        <Card sx={{ opacity: 0.95, mt: 1 }}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
            >
                <Tooltip title={
                    <Typography fontSize={12}>
                        Download image.
                        <br />
                        Note: Reset filters to get raw image.
                    </Typography>
                }
                    placement="bottom-end">
                    <IconButton onClick={downloadImage}>
                        <DownloadIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Card>
        <svg ref={svgRef} style={{ display: 'none' }}>
            {/* almost everything is hardcoded here so it doesn't apply the padding to the downloaded image */}
            <AnnotationLayer
                rest={{}}
                orientationGuides={null}
                zoomLevel={0.5}
                svgRef={svgRef}
                mouseDown={() => { }}
                mouseMove={() => { }}
                doubleClick={() => { }}
                imageDimensions={imageDimensions}
                annotationList={annotationList}
                imageMeasurementPadding={{ horizontal: 0, vertical: 0 }}
            />
        </svg>
    </Box>;

}
