import QrCodeIcon from '@mui/icons-material/QrCode';
import { Card, CardContent, IconButton, Modal, Stack, TableSortLabel, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import QRCode from 'qrcode.react';
import { useState } from 'react';

import { portalColorScheme } from '../../utils/colorScheme';
import { borderColor } from '../../utils/tableFormats';
import {
    cellModelOrder,
    companiesOrder,
    customerOrder,
    membershipOrder,
    requestsOrder,
    scanOrder,
    scanTypeOrder,
    scannerOrder,
    tosRecordsOrder,
    userLogsOrder,
    userOrder
} from './internalPageUtils';
import { InternalPageMenuOptions } from './internalTypes';

export default function TableViewer(
    { tableData, sortedColumn, sortDirection, handleSortClick, selectedTable }:
        { tableData: any[], sortedColumn: string | null, sortDirection: "asc" | "desc", handleSortClick: (key: string) => void, selectedTable: InternalPageMenuOptions },
) {
    const [open, setOpen] = useState(false);
    const [qrCodeData, setQrCodeData] = useState('');

    if (tableData.length === 0) {
        return <div>No data</div>
    }

    const tableKeys = Object.keys(tableData[0])

    const handleOpen = (value: string) => { setOpen(true); setQrCodeData(value) };
    const handleClose = () => { setOpen(false); setQrCodeData('') };



    const columnOrder = () => {
        switch (selectedTable) {
            case InternalPageMenuOptions.REQUESTS:
                return requestsOrder
            case InternalPageMenuOptions.SCAN_TYPES:
                return scanTypeOrder
            case InternalPageMenuOptions.CELL_MODELS:
                return cellModelOrder
            case InternalPageMenuOptions.REQUEST_GROUPS:
                return customerOrder
            case InternalPageMenuOptions.REQUEST_GROUP_MEMBERSHIP:
                return membershipOrder
            case InternalPageMenuOptions.USERS:
                return userOrder
            case InternalPageMenuOptions.SCANNER:
                return scannerOrder
            case InternalPageMenuOptions.SCANS:
                return scanOrder
            case InternalPageMenuOptions.COMPANIES:
                return companiesOrder
            case InternalPageMenuOptions.TOS_RECORDS:
                return tosRecordsOrder
            case InternalPageMenuOptions.USER_LOGS:
                return userLogsOrder
            case InternalPageMenuOptions.GLIMPSEBOXES:
                return []
        }
    }
    const orderedColumns = tableKeys
        .filter((col) => columnOrder().includes(col))
        .sort((a, b) => columnOrder().indexOf(a) - columnOrder().indexOf(b));

    const valueSanitizer = (value: any) => {
        if (value === null) {
            return ''
        }
        if (typeof value === 'object') {
            return JSON.stringify(value)
        }
        return value.toString()
    }

    const qrCodeManager = (key: string, value: any, selectedTable: InternalPageMenuOptions) => {
        if (selectedTable === InternalPageMenuOptions.REQUESTS && key === 'uuid') {
            return <IconButton
                size="small"
                onClick={() => handleOpen(value)}
            >
                <QrCodeIcon />
            </IconButton>
        }
    }

    const cellStyle = {
        p: 0.2, borderColor: borderColor
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card sx={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'
                }}>
                    <CardContent>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Request uuid QR code
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 5 }}>
                            Contains text: <i> {qrCodeData} </i>
                        </Typography>
                        <Stack direction="row" justifyContent='center' spacing={2} sx={{ mt: 2, mb: 5 }}>
                            <Card sx={{ backgroundColor: portalColorScheme.white, p: 7 }}>
                                <QRCode size={220} value={qrCodeData} style={{ border: 3 }} />
                            </Card>
                        </Stack>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Click anywhere to exit
                        </Typography>
                    </CardContent>
                </Card>
            </Modal>
            <TableContainer style={{ maxHeight: '70vh' }} >
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {orderedColumns.map((key) => (
                                <TableCell key={key} sx={cellStyle}>
                                    <TableSortLabel
                                        active={sortedColumn === key}
                                        direction={sortedColumn === key ? sortDirection : 'asc'}
                                        onClick={() => handleSortClick(key)}
                                    >
                                        {key}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map(
                            (e: any) => (
                                <TableRow key={e.id}
                                >
                                    {orderedColumns.map((key) => (
                                        <TableCell key={key} sx={cellStyle} >
                                            {valueSanitizer(e[key])}
                                            {qrCodeManager(key, e[key], selectedTable)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer >
        </>
    );
}
