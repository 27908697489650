import CSSBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ReactDOM from 'react-dom/client';
import { AuthProviderWrapper } from "./auth/AuthProviderWrapper";
import './index.css';
import reportWebVitals from './reportWebVitals';

const theme = createTheme({
  typography: {
    fontFamily: 'Avenir, Avenir Next LT Pro, Montserrat, sans-serif',
  },
  palette: {
    mode: 'dark',
    background: {
      default: "#030C30", // Or #0D1742
      paper: "#030C30",
    }
  },
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // Temporarily disabling strict mode to better manage API calls
  // Strict mode renders and calls everything twice in development
  //  which is great for error detection but a PITA for when I'm
  //  trying to manage api calls
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <CSSBaseline />
    <AuthProviderWrapper />
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
