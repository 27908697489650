import { CommonSvgShapes } from "../../../types";

export default function MeasurementLayerRadiusSvgs(
    { radius, strokeWidth }: {
        radius: CommonSvgShapes[],
        strokeWidth: string | number,
    }
) {
    return (
        <>
            {
                radius.map(e => {
                    const points = e.coordinates.map(coord => `${coord[0]},${coord[1]}`).join(' ');

                    const [edgeCoord, centerCoord] = e.coordinates;
                    const centerX = centerCoord[0];
                    const centerY = centerCoord[1];

                    const radiusLength = Math.sqrt(
                        Math.pow(edgeCoord[0] - centerCoord[0], 2) +
                        Math.pow(edgeCoord[1] - centerCoord[1], 2)
                    );

                    return (
                        <g key={e.id}>
                            <polyline
                                points={points}
                                stroke={e.color}
                                strokeWidth={strokeWidth}
                                strokeDasharray="5,5"
                                fill="none"
                            />
                            <circle
                                cx={centerX}
                                cy={centerY}
                                r={radiusLength}
                                stroke={e.color}
                                strokeWidth={strokeWidth}
                                fill="none"
                            />
                        </g>
                    );
                })
            }
        </>
    );
}
