import { Box, ButtonBase, List, ListItem, TextField, Typography } from "@mui/material";
import { portalColorScheme } from "../../utils/colorScheme";

export interface Question {
    question: string;
    answer: string;
}

export default function SearchBar(
    { handleSearchDropdown,
        searchResults,
        handleSelectQuestion,
        searchText,
        filteredQuestions,
    }:
        {
            handleSearchDropdown: (event: React.ChangeEvent<HTMLInputElement>) => void,
            searchResults: boolean,
            handleSelectQuestion: (selectedQuestion: Question) => void
            searchText: string,
            filteredQuestions: Question[]
        }
) {

    return (
        <>
            {/* SearchBar */}
            < TextField
                label="Search"
                value={searchText}
                onChange={handleSearchDropdown}
                sx={{ width: "100%", marginBottom: "20px", color: portalColorScheme.white }
                }
                InputProps={{
                    style: { backgroundColor: portalColorScheme.mutedBlue, borderRadius: "50px" }
                }}
            />
            {/* SearchBar dropdown */}
            {
                searchResults && (
                    <Box sx={{ width: "100%", height: "70px", backgroundColor: portalColorScheme.mutedBlue, marginTop: "-50px", }} />

                )
            }
            <Box sx={{
                marginBottom: "20px", width: "250px", maxHeight: "200px", overflowY: "auto", backgroundColor: portalColorScheme.mutedBlue,
                borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px",
                borderTopLeftRadius: "5px", borderTopRightRadius: "5px", marginTop: "-10px"
            }}>
                {searchResults && (
                    <List>
                        {filteredQuestions.length > 0 ? (
                            filteredQuestions.map(question => (
                                <ListItem component={ButtonBase} onClick={() => handleSelectQuestion(question)} sx={{ '&:hover .hover-text': { fontWeight: 'bold', } }}>
                                    <Box sx={{ marginBottom: "5px", display: 'inline-block', minWidth: 0 }} >
                                        <Typography className="hover-text" sx={{ color: portalColorScheme.white }}>{question.question} </Typography>
                                    </Box>
                                </ListItem>
                            ))
                        ) : (
                            <ListItem sx={{ marginTop: "-10px" }}> No results found</ListItem>
                        )}
                    </List>

                )}
            </Box>
        </>
    );


}
