import useUrlState from "@ahooksjs/use-url-state";
import { useEffect, useState } from "react";
import { useAuthTokenAndAccessApi } from "../auth/authHooks";
import { MeasurementDimensions, PageOptions, ScanList, UserAccessState } from "../types";
import { ApiEndpoints } from "../utils/apiUtils";
import { hotjarInit } from "../utils/hotjarInit";
import { MetricDisplayNames } from "../utils/inspection";
import AppMainLayoutComponent from "./AppMainLayoutComponent";



export default function AppInitialDataLoading() {
    const [urlState, setUrlState] = useUrlState<{ page: PageOptions, public?: boolean }>({ page: PageOptions.SCANS })
    const { fetchData, isLoading, isAuthenticated, userEmail, isVerified } = useAuthTokenAndAccessApi();
    const [userAccessState, setUserAccessState] = useState<UserAccessState>(UserAccessState.LOADING)
    const [scanList, setScanList] = useState<ScanList[]>([{} as ScanList])
    const [availableMetrics, setAvailableMetrics] = useState([] as MeasurementDimensions[])
    const [isDemoMode, setIsDemoMode] = useState<boolean>(true)

    useEffect(() => {
        if ((isAuthenticated && !isLoading) || urlState.page === PageOptions.FAQ) {
            loadUserFromApi()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isLoading])

    const loadUserFromApi = async () => {
        // TODO: This status needs a guard for our on-prem deployment (email verified won't exist).
        const isVerifiedBool = await isVerified();
        if (!isVerifiedBool) {
            setUserAccessState(UserAccessState.EMAIL_UNVERIFIED)
            return
        }

        try {
            const userStatus = await fetchData(ApiEndpoints.CHECK_USER);
            if (!userStatus) {
                setUserAccessState(UserAccessState.SIGNUP_INCOMPLETE)
                return
            }
            const status = userStatus.data.user_access_status as UserAccessState
            switch (status) {
                case UserAccessState.SIGNUP_COMPLETE:
                case UserAccessState.GLIMPSE_INTERNAL_READ:
                case UserAccessState.GLIMPSE_INTERNAL_WRITE:
                case UserAccessState.CUSTOMER_OPERATOR:
                    if (urlState.public === "true") {
                        setIsDemoMode(true);
                        setUrlState({ page: urlState.page, public: true })
                        await initialDataLoader(true);
                    }
                    else if (urlState.public === "false") {
                        setIsDemoMode(false);
                        setUrlState({ page: urlState.page, public: false })
                        await initialDataLoader(false);
                    }
                    // if no state provided in the link, assume private:
                    else {
                        setIsDemoMode(false);
                        setUrlState({ page: urlState.page, public: false })
                        await initialDataLoader(false);
                    }
                    break;
                case UserAccessState.SIGNUP_INCOMPLETE:
                default:
                    setIsDemoMode(true)
                    setUrlState({ page: urlState.page, public: true })
                    await initialDataLoader(true)
                    break;
            }
            setUserAccessState(status)
            if (userEmail) { hotjarInit(userEmail) }
        } catch (error: any) {
            console.log(error)
            setUserAccessState(UserAccessState.ERROR)
        }
    }

    const handleDemoToggle = () => {
        initialDataLoader(!isDemoMode)
        setIsDemoMode(!isDemoMode);
        setUrlState({ page: urlState.page, public: !isDemoMode })
    }

    async function initialDataLoader(isDemoMode: boolean) {
        await loadScanListFromApi(isDemoMode).then(() => getAvailableMetrics())
    }

    async function getAvailableMetrics() {
        try {
            const metricResponse = await fetchData(ApiEndpoints.INSPECTION_METRICS);
            setAvailableMetrics(metricResponse.data.map((m: {
                metric_id: number
                metric_internal_name: string
                orientation: string
            }) => {
                const findMetric = MetricDisplayNames.find((e) => e.internal_name === m.metric_internal_name)
                return {
                    metric_id: m.metric_id,
                    metric_internal_name: m.metric_internal_name,
                    orientation: m.orientation,
                    metric_display_name: findMetric?.display_name,
                    description: findMetric?.description,
                } as MeasurementDimensions
            }
            )
            );
        } catch (error: any) {
            console.log(error)
        }
    }

    const loadScanListFromApi = async (demoMode: boolean) => {
        let url = ApiEndpoints.SCAN_LIST
        if (demoMode) url = ApiEndpoints.DEMO_SCAN_LIST
        const scanResponse = await fetchData(url);
        if (scanResponse.data.length === 0) setScanList([] as ScanList[])
        if (scanResponse.data.length > 0) setScanList((scanResponse.data as ScanList[]).sort((a, b) => b.scan_id - a.scan_id));
    }

    return <AppMainLayoutComponent
        selectedPage={urlState.page}
        userAccessState={userAccessState}
        isLoginLoading={isLoading}
        isUserAuthenticated={isAuthenticated}
        scanList={scanList}
        availableMetrics={availableMetrics}
        isDemoMode={isDemoMode}
        handleDemoToggle={handleDemoToggle}
    />
}
