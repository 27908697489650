import { CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { portalColorScheme } from '../utils/colorScheme';

export default function SomethingWentWrongPage() {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <CardContent>
                <Typography variant="h5" component="div" sx={{ flexGrow: 1, mt: 8, color: portalColorScheme.white }}>
                    Something went wrong.
                </Typography>
                <Typography variant="body1" sx={{ flexGrow: 1, mt: 2, color: portalColorScheme.white }}>
                    Try waiting a few seconds and reloading, and please let us know if this keeps happening.
                </Typography>
            </CardContent>
        </Grid>
    );
}
