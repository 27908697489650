

export enum ScanStatusOptions {
    IN_VOLUME_QUEUE = "in volume_paths.ready queue",
    IN_JOBS_QUEUE = "sent to jobs.ready queue",
    POST_PROCESSING_STARTED = "is running pipeline on glimpsebox",
    ERROR_IN_PROCESSING = "error detected",
    POST_PROCESSING_COMPLETED = "processing completed successfully",
    UNKNOWN_ERROR = "unknown error"
}

export enum QueueTypes {
    VOLUME_PATHS_READY = "volume_paths.ready",
    JOBS_READY = "jobs.ready",
    VOLUME_PATHS_FAILED = "volume_paths.failed",
    JOBS_FAILED = "jobs.failed"
}

export interface LogEntry {
    created: Date,
    event_type: string,
    id: number,
    scan_id: number,
    batch_name: string,
    serial_number: string,
    status: string,
}

export interface ReducedLogs {
    scanId: number;
    minCreated: Date;
    maxCreated: Date;
    latestEventType: string;
    errorDetected: boolean;
    batchName: string;
    serialNumber: string;
}

export interface ParentTableData {
    queueId?: number;
    scanId?: number;
    status_message?: ScanStatusOptions;
    serialNumber: string;
    batch: string | null;
    minCreated: string;
    maxCreated: string;
    duration: number | null;
}

export interface VolumeQueueRaw {
    target_glimpsebox: string,
    timestamp: Date,
    volume_name: string,
    volume_path: string,
}

export interface JosQueueRaw {
    scan_dims: any, // {VoxelsX, VoxelsY, Voxels} not used right now
    timestamp?: Date, // placeholder at the moment
    scan_info: {
        request_group_id: number,
        request_id: number,
        scan_id: number,
        scan_type_id: number,
    }, // large json with a bunch of stuff, not used yet.
    scan_path: string, // like volume path but not
    volume_name: string,
    upload_flags: {
        upload_post_processed: boolean,
        upload_projections: boolean,
        upload_recon: boolean
    },
}


export interface Queues {
    id?: number,
    scan_id?: number,
    timestamp: Date,
    volume_name: string,
    volume_path: string,
    queue: QueueTypes
}


export enum ScanEventLogEntries {
    // Should correspond to messages here: glimpse/scan_processing/pipeline/profiling.py
    // happens before "post processing" starts:
    SCAN_ID_CREATED = "scan_id_created",
    SCAN_METADATA_VALIDATION = "scan_metadata_validation",
    // pipeline on glimpsebox:
    VALIDATE_VOLUME_PATH_REGISTRATION = "validate_volume_path_registration",
    VALIDATE_VOLUME_PATH_PROCESSING = "validate_volume_path_processing",
    LOAD_VOLUME = "load_volume",
    VALIDATE_VOLUME = "validate_volume",
    POST_PROCESSING_STARTED = "post_processing_started",
    ADJUST_CONTRAST = "adjust_contrast",
    ALIGN = "align",
    CROP_RADIAL = "crop_radial",
    MASK_RADIAL = "mask_radial",
    SLICE_RADIAL = "slice_radial",
    CROP_AXIAL = "crop_axial",
    FLIP_AXIAL = "flip_axial",
    ROTATE_SCAN = "rotate_scan",
    SLICE_AXIAL = "slice_axial",
    SLICE_SAVING = "slice_saving",
    SLICE_UPLOAD = "slice_upload",
    ORIENT = "orient",
    VALIDATE_ALIGNMENT = "validate_alignment",
    CROP_IN_ONE_DIMENSION = "crop_in_one_dimension",
    SLICE_CARTESIAN_AXIS0 = "slice_cartesian_axis0",
    SLICE_CARTESIAN_AXIS1 = "slice_cartesian_axis1",
    SLICE_CARTESIAN_AXIS2 = "slice_cartesian_axis2",
    RECON_UPLOAD = "recon_upload",
    VOLUME_UPLOAD = "volume_upload",
    SAVE_PROCESSED_VOLUME = "save_processed_volume",
    SLACK_IMAGE_LOGGING = "slack_image_logging",
    // last step:
    POST_PROCESSING_COMPLETED = "post_processing_completed",
}

export enum ScanEventLogStatus {
    ERROR = "error",
    SUCCESS = "success"
}

export function reduceScanLogs(data: LogEntry[]): ReducedLogs[] {
    // TODO: this is ugly, and could be combined with the statusCalculatorFromRawDbLog function and dropped
    //       the main purpose of this right now is to calculate the min and max times for a duration calculation
    return data.reduce((acc: ReducedLogs[], curr: LogEntry) => {
        const existingIndex = acc.findIndex(entry => entry.scanId === curr.scan_id);
        const currTime = new Date(curr.created);
        if (existingIndex !== -1) {
            // Update existing entry:
            const entry = acc[existingIndex];
            entry.minCreated = currTime < entry.minCreated ? currTime : entry.minCreated;
            entry.maxCreated = currTime > entry.maxCreated ? currTime : entry.maxCreated;
            if (currTime > entry.maxCreated) {
                entry.latestEventType = curr.event_type;
            };
            entry.errorDetected = curr.status === ScanEventLogStatus.ERROR || entry.errorDetected;
        } else {
            // Add new entry:
            acc.push({
                scanId: curr.scan_id,
                batchName: curr.batch_name,
                serialNumber: curr.serial_number,
                minCreated: currTime,
                maxCreated: currTime,
                latestEventType: curr.event_type,
                errorDetected: curr.status === ScanEventLogStatus.ERROR
            });
        }
        return acc;
    }, []);
}

export const checkForTimeout = (lastLogDate: Date, timeoutLengthInSeconds: number) => {
    // check for age of logs
    const timeoutLimitInMs = timeoutLengthInSeconds * 1000;
    const lastLogTime = lastLogDate;
    const lastLogPlusTimeoutInMs = new Date(lastLogTime.valueOf() + timeoutLimitInMs);
    return (Date.now() - lastLogTime.valueOf()) > timeoutLimitInMs ? lastLogPlusTimeoutInMs : null;
}

/**
* @description: This is used to determine what message to display the operator at the parent table.
*/
export const statusCalculatorFromRawDbLog = (entry: ReducedLogs) => {
    function isValueInEnum(value: string, enumType: any): boolean {
        return Object.values(enumType).includes(value);
    }
    if (entry.errorDetected) return ScanStatusOptions.ERROR_IN_PROCESSING;
    if (isValueInEnum(entry.latestEventType, ScanEventLogEntries)) {
        switch (entry.latestEventType) {
            case ScanEventLogEntries.SCAN_ID_CREATED:
            case ScanEventLogEntries.SCAN_METADATA_VALIDATION: //TODO: drop when code is next pulled
                return ScanStatusOptions.IN_JOBS_QUEUE;
            case ScanEventLogEntries.POST_PROCESSING_COMPLETED:
                return ScanStatusOptions.POST_PROCESSING_COMPLETED;
            default:
                if (checkForTimeout(entry.maxCreated, 300)) return ScanStatusOptions.ERROR_IN_PROCESSING;
                return ScanStatusOptions.POST_PROCESSING_STARTED;
        }
    }
    return ScanStatusOptions.UNKNOWN_ERROR;
}

export const externalOperatorParentTableRenamer = (status: ScanStatusOptions) => {
    switch (status) {
        case ScanStatusOptions.IN_JOBS_QUEUE:
        case ScanStatusOptions.IN_VOLUME_QUEUE:
            return "Queued for processing"
        case ScanStatusOptions.POST_PROCESSING_STARTED:
            return "Processing started"
        case ScanStatusOptions.POST_PROCESSING_COMPLETED:
            return "Processing completed"
        case ScanStatusOptions.ERROR_IN_PROCESSING:
            return "Error during processing"
    }
}

export const externalOperatorEventRenamer = (event: ScanEventLogEntries) => {
    switch (event) {
        case ScanEventLogEntries.SCAN_ID_CREATED:
            return 'Scan Id registered';
        case ScanEventLogEntries.POST_PROCESSING_STARTED:
            return 'Scan processing started';
        case ScanEventLogEntries.POST_PROCESSING_COMPLETED:
            return 'Scan uploading completed';
        case ScanEventLogEntries.SLICE_UPLOAD:
            return "Uploading data to portal";
        default:
            // catch-all for error message renaming:
            return "Scan processing"
    }
}
export const externalOperatorStatusRenamer = (event: ScanEventLogEntries, status: ScanEventLogStatus) => {
    if (status === ScanEventLogStatus.ERROR) {
        switch (event) {
            case ScanEventLogEntries.LOAD_VOLUME:
                return 'Error loading the volume. Check the volume integrity and rescan if necessary.';
            case ScanEventLogEntries.VALIDATE_VOLUME:
                return 'Error in volume integrity check: volume appears to be corrupted or empty, please rescan the cell';
            case ScanEventLogEntries.SCAN_METADATA_VALIDATION:
                return 'Error with the scan recipe: It looks like the scan did not match the expected scan recipe. Please check the scan recipe and try again.';
            case ScanEventLogEntries.CROP_RADIAL:
                return 'Error cropping the volume. Cell appears to be cut off on the side. Please rescan the cell.';
            case ScanEventLogEntries.CROP_AXIAL:
                return 'Problem with the cropping of the scan: It looks like the cell was improperly placed in its fixture. Please check and try scanning again by ensuring the cell is properly fixtured.';
            case ScanEventLogEntries.VALIDATE_VOLUME_PATH_REGISTRATION:
                return 'Failed to load the volume into the GlimpseBox. Check that the network cable which connects the reconstruction computer and the GlimpseBox is plugged in.';
            default:
                return 'Error during scan processing: something went wrong with during scan processing, please contact support.';
        }
    }
    return status;
}
