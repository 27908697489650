import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { portalColorScheme } from '../utils/colorScheme';

export default function NotFoundPage() {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Typography variant="h5" component="div" sx={{ flexGrow: 1, mt: 8, color: portalColorScheme.white }}>
                Page not found
            </Typography>
            <Typography variant="body1" sx={{ color: portalColorScheme.white }}>
                Go back, or clear the url and try again. Please let us know if this keeps happening.
            </Typography>
        </Grid>
    );
}
