import { ScanAxes } from "../../types";
import { ApiEndpoints } from "../../utils/apiUtils";
import {
    AddCellModelObject,
    AddCompanyObject,
    AddMembershipObject,
    AddRequestGroupObject,
    AddRequestObject,
    AddScanTypeObject, AddUserObject, FieldOfViewOptions, FormFactorOptions,
    InternalPageMenuOptions, UserPermissionOptions
} from './internalTypes';

/**
* @description - function returns true when all criteria are met for the form to be submitted
TODO: validate these with testing. If XYZ then not certain other fields, etc.?
*/
export const formValidator = (formState: any, selectedTable: InternalPageMenuOptions) => {
    switch (selectedTable) {
        case InternalPageMenuOptions.REQUESTS:
            return formState.name !== "" && formState.cell_model_id !== ""
                && formState.request_group_id !== "" && formState.requesting_user_id !== ""
                && formState.scan_type_id !== "" && formState.quantity > 0
        case InternalPageMenuOptions.SCAN_TYPES:
            return formState.name !== "" && formState.description !== ""
                && formState.field_of_view !== "" && formState.nominal_cycle_time_s > 0
                && formState.nominal_scan_time_s > 0 && formState.voxel_size_mm > 0
        case InternalPageMenuOptions.CELL_MODELS:
            return formState.name !== "" && formState.vendor !== "" && formState.axes !== ""
                && formState.form_factor !== "" && ((formState.data.can_diameter_mm > 0 && formState.data.can_height_mm > 0) ||
                    (formState.data.can_depth_mm > 0 && formState.data.can_height_mm > 0 && formState.data.can_width_mm > 0))
        case InternalPageMenuOptions.REQUEST_GROUPS:
            return formState.name !== ""
        case InternalPageMenuOptions.COMPANIES:
            return formState.name !== "" && formState.legal_name !== "" && formState.domain !== ""
        case InternalPageMenuOptions.REQUEST_GROUP_MEMBERSHIP:
            return formState.user_id !== 0 && formState.request_group_id !== 0
                && formState.user_permissions !== ""
        case InternalPageMenuOptions.USERS:
            return formState.user_name !== "" && formState.email !== "" && formState.email.includes("@")
                && !formState.email.includes(' ') && formState.company_id !== 0
                && formState.email === formState.email.toLowerCase()
        default:
            return false
    }
}

/**
* @description - function to add some description text where needed to certain fields
*/
export const formFieldTextEditor = (field: string) => {
    switch (field) {
        case "can_diameter_mm":
        case "can_depth_mm":
        case "can_width_mm":
        case "can_height_mm": return field + " (measured)";
        case "user_permissions":
            return "user_permissions: RO (read only), RW (read-write), admin. [we only use RO for external customers right now, defaults to RO if nothing selected]";
        case "email": return "email: must be a valid email address, must match exactly what email will be used on auth0, must not contain any spaces, and be all lower case";
        case "auth0_id": return "auth0_id: optional, and legacy - add this if the user has already signed up on auth0, not required not used for any functionality.";
        case "field_of_view": return "field_of_view: usually 'full', if nothing selected we assume 'full'"
        case "name": return "name: cannot be blank";
        case "description": return "description: cannot be blank";
        case "vendor": return "vendor: cannot be blank";
        case "nominal_cycle_time_s": return "nominal_cycle_time_s: in seconds, must be greater than 0";
        case "nominal_scan_time_s": return "nominal_scan_time_s: in seconds, must be greater than 0";
        case "voxel_size_mm": return "voxel_size_mm: must be greater than 0";
        case "quantity": return "quantity: must be greater than 0";
        case "company_id": return "company_id: must be filled in, this does NOT determine what scans are visible to the user, this field is for terms of service and other user management.";
        default: return field;
    }
}

/**
* @description - Formats the dropdown options for the dropdown component, so we can choose what items to display for the operator.
IMPORTANT: these must start with id because we're using an Autocomplete component that expects the first item to be the id.
*/
export const formatDropdownOptions = (
    item: string,
    option: any
) => {
    switch (item) {
        case "cell_model_id": return `${option.id}, ${option.name}, ${option.form_factor}, ${option.axes}, ${option.vendor}`;
        case "request_group_id": return `${option.id}, ${option.name}`;
        case "user_id": // same as "requesting_user_id"
        case "requesting_user_id": return `${option.id}, ${option.user_name}, ${option.email}`;
        case "scan_type_id": return `${option.id}, ${option.name}, ${option.description}, ${option.voxel_size_mm} mm`;
        case "company_id": return `${option.id}, ${option.name} (${option.legal_name})`;
        default: return option;
    }
}

/**
* @description - selects the correct api endpoint string or dropdown enum for each dropdown component, based on the field
*/
export const dropdownPopulator = (field: string) => {
    switch (field) {
        case "cell_model_id": return { api: true, endpoint: ApiEndpoints.INTERNAL_CELL_MODEL };
        case "request_group_id": return { api: true, endpoint: ApiEndpoints.INTERNAL_REQUEST_GROUP };
        case "requesting_user_id": return { api: true, endpoint: ApiEndpoints.INTERNAL_USERS };
        case "scan_type_id": return { api: true, endpoint: ApiEndpoints.INTERNAL_SCAN_TYPE };
        case "user_id": return { api: true, endpoint: ApiEndpoints.INTERNAL_USERS };
        case "company_id": return { api: true, endpoint: ApiEndpoints.INTERNAL_COMPANIES };
        case "user_permissions": return { api: false, enum: Object.values(UserPermissionOptions) };
        case "axes": return { api: false, enum: Object.values(ScanAxes) };
        case "form_factor": return { api: false, enum: Object.values(FormFactorOptions) };
        case "field_of_view": return { api: false, enum: Object.values(FieldOfViewOptions) };
    };
    return "";
}

/**
* @description - instantiates the correct type for the form state, and sets default values
*/
export const setStateFromSelectedTable = (selectedTable: InternalPageMenuOptions) => {
    switch (selectedTable) {
        case InternalPageMenuOptions.REQUESTS:
            return {
                cell_model_id: '',
                request_group_id: '',
                requesting_user_id: '',
                scan_type_id: '',
                upload_recon: false,
                upload_projections: false,
                upload_post_processed: false,
                name: '',
                quantity: 0,
                data: null
            } as AddRequestObject
        case InternalPageMenuOptions.SCAN_TYPES:
            // Check cases here!
            return {
                description: '',
                field_of_view: 'full',
                name: '',
                nominal_cycle_time_s: 0,
                nominal_scan_time_s: 0,
                scan_metadata: null,
                updated: null,
                voxel_size_mm: 0,
                data: { VoxelsX: 0, VoxelsY: 0, VoxelsZ: 0 }
            } as AddScanTypeObject
        case InternalPageMenuOptions.CELL_MODELS:
            return {
                axes: '',
                data: {
                    can_diameter_mm: 0,
                    can_height_mm: 0,
                },
                form_factor: '',
                name: '',
                updated: null,
                vendor: '',
            } as AddCellModelObject
        case InternalPageMenuOptions.REQUEST_GROUPS:
            return {
                is_active: true,
                name: '',
                created: null,
                updated: null,
                data: null,
                is_public: false
            } as AddRequestGroupObject
        case InternalPageMenuOptions.REQUEST_GROUP_MEMBERSHIP:
            return {
                user_id: 0,
                request_group_id: 0,
                user_permissions: UserPermissionOptions.READONLY,
                created: null,
                data: null,
            } as AddMembershipObject
        case InternalPageMenuOptions.COMPANIES:
            return {
                name: '',
                legal_name: '',
                domain: '',
                is_active: true,
                data: null,
                require_tos_signing: false
                // designated_signatory: '',
                // auth0_org: '',
                // created: null,
                // updated: null,
            } as AddCompanyObject

        case InternalPageMenuOptions.USERS:
        default:
            return {
                user_name: '',
                email: '',
                auth0_id: '',
                company_id: 0,
                created: null,
                is_active: true,
                updated: null,
                data: null,
            } as AddUserObject
    }
}


/**
* @description - object informs what form fields need to be shown for data entry
*/
export const formInputFields = (selectedTable: InternalPageMenuOptions) => {
    switch (selectedTable) {
        case InternalPageMenuOptions.REQUESTS:
            return {
                dropdowns: ["cell_model_id", "request_group_id", "requesting_user_id", "scan_type_id"],
                checkboxes: ["upload_recon", "upload_projections", "upload_post_processed"],
                textFields: ["name"],
                numberFields: ["quantity"],
                endpoint: ApiEndpoints.INTERNAL_REQUESTS
            }
        case InternalPageMenuOptions.SCAN_TYPES:
            return {
                dropdowns: ["field_of_view"],
                checkboxes: [],
                textFields: ["description", "name"],
                numberFields: ["nominal_cycle_time_s", "nominal_scan_time_s", "voxel_size_mm", "VoxelsX", "VoxelsY", "VoxelsZ"],
                endpoint: ApiEndpoints.INTERNAL_SCAN_TYPE
            }
        case InternalPageMenuOptions.CELL_MODELS:
            return {
                dropdowns: ["axes", "form_factor"],
                checkboxes: [],
                textFields: ["name", "vendor"],
                numberFields: [],
                endpoint: ApiEndpoints.INTERNAL_CELL_MODEL
            }
        case InternalPageMenuOptions.REQUEST_GROUPS: // leaving blank for now, until we have TOS sorted better.
            return {
                dropdowns: [],
                checkboxes: [],
                textFields: ["name"],
                numberFields: [],
                endpoint: ApiEndpoints.INTERNAL_REQUEST_GROUP
            }
        case InternalPageMenuOptions.REQUEST_GROUP_MEMBERSHIP:
            return {
                dropdowns: ["user_id", "request_group_id", "user_permissions"],
                checkboxes: [],
                textFields: [],
                numberFields: [],
                endpoint: ApiEndpoints.INTERNAL_MEMBERSHIPS
            }
        case InternalPageMenuOptions.USERS:
            return {
                dropdowns: ["company_id"],
                checkboxes: [],
                textFields: ["user_name", "email"],
                numberFields: [],
                endpoint: ApiEndpoints.INTERNAL_USERS
            }
        case InternalPageMenuOptions.COMPANIES:
            return {
                dropdowns: [],
                checkboxes: [],
                textFields: ["name", "legal_name", "domain"],
                numberFields: [],
                endpoint: ApiEndpoints.INTERNAL_COMPANIES
            }
        default:
            return { dropdowns: [], checkboxes: [], textFields: [], numberFields: [], endpoint: "" }
    }
}

/**
* @description - assumes autocomplete string is in the format "id, otherstuff...." and returns the id
*/
export const parseIdFromString = (inputString: string) => {
    const regex = /^(\d+),/;
    const match = regex.exec(inputString);
    if (match && match[1]) {
        return parseInt(match[1], 10);
    } else {
        return null;
    }
}

export const requestsOrder = [
    "id",
    "name",
    "cell_model_id",
    "cell_model_name",
    "requested_quantity",
    "scanned_count_total",
    "scanned_count_unflagged",
    "scanned_count_complete",
    "scanned_count_complete_and_unflagged",
    "requesting_user_id",
    "uuid",
    "created",
    "request_group_id",
    "request_group_name",
    "scan_type_id",
    "scan_type_name",
    "data",
    "upload_recon",
    "upload_projections",
    "upload_post_processed",
]

export const scanTypeOrder = [
    "id",
    "name",
    "created",
    "voxel_size_mm",
    "description",
    "field_of_view",
    "nominal_cycle_time_s",
    "nominal_scan_time_s",
    "scan_metadata",
    "updated",
    "data",
]

export const cellModelOrder = [
    "id",
    "name",
    "updated",
    "vendor",
    "axes",
    "form_factor",
    "created",
    "data",
]

export const customerOrder = [
    "id",
    "is_active",
    "name",
    "created",
    "domain",
    "auth0_org",
    "updated",
    "data",
]

export const membershipOrder = [
    "id",
    "user_name",
    "user_email",
    "request_group_name",
    "user_id",
    "request_group_id",
    "user_permissions",
    "created",
    "data",
]

export const userOrder = [
    "id",
    "user_name",
    "email",
    "company_id",
    "company_name",
    "auth0_id",
    "created",
    "is_active",
    "updated",
    "data",
]

export const scannerOrder = [
    "id",
    "name",
    "vendor",
    "is_active",
    "is_online",
    "location",
    "model",
    "serial",
    "updated",
    "created",
    "data",
]

export const scanOrder = [
    "id",
    "request_id",
    "scanner_id",
    "cell_id",
    "cell_serial_number",
    "request_group_name",
    "flagged",
    "post_processing_completed",
    "request_name",
    "cell_model_name",
    "cell_model_id",
    "scan_type_name",
    "scan_type_id",
    "scanner_name",
    "data",
]


export const companiesOrder = ["id", "name", "legal_name", "domain", "auth0_org", "is_active",
    "designated_signatory", "created", "updated", "data", "require_tos_signing"]
export const tosRecordsOrder = ["id", "company_id", "tos_version", "created", "signatory"]

export const userLogsOrder = ["time", "email", "url"]
