import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import { ManualMeasurementOptions, ManualMeasurementResults, MeasurementStatus } from '../../types';
import { overlayColorScheme } from '../../utils/colorScheme';


export default function ManualMeasurementList(
    { measurementList,
        removeMeasurement,
        boxHeight,
        setHighlightedMeasurement,
        toggleDisplay,
        measurementStatus
    }: {
        measurementList: ManualMeasurementResults[],
        removeMeasurement: (id: number) => void,
        boxHeight: number,
        setHighlightedMeasurement: (id: number) => void,
        toggleDisplay: (id: number) => void,
        measurementStatus: MeasurementStatus

    }
) {

    const unit = (measurementType: string) => {
        switch (measurementType) {
            case ManualMeasurementOptions.LINE:
                return 'mm'
            case ManualMeasurementOptions.ANGLE:
                return ''
            case ManualMeasurementOptions.POLYGON:
                return 'mm'
            case ManualMeasurementOptions.RADIUS:
                return 'mm'
            case ManualMeasurementOptions.POLYLINE:
                return 'mm'
            default:
                return 'mm'
        }
    }
    const measurementLabel = (measurementType: string) => {
        switch (measurementType) {
            case ManualMeasurementOptions.LINE:
                return 'Length'
            case ManualMeasurementOptions.ANGLE:
                return 'Angle'
            case ManualMeasurementOptions.POLYGON:
                return 'Area'
            case ManualMeasurementOptions.RADIUS:
                return 'Radius'
            case ManualMeasurementOptions.POLYLINE:
                return 'Length'
            default:
                return 'Length'
        }
    }
    const cellSx = { borderTop: 0, borderLeft: 0, borderRight: 0, pl: 0.5, pr: 0.5, pb: .2, pt: .2, m: 0, width: 'auto' }
    return <>
        <Stack sx={{ height: boxHeight, width: '100%', ml: 0.5, mr: 0.5 }} >
            <Typography variant='body2' sx={{ ml: 1, mt: 1, alignSelf: 'center' }}>
                Manual Inspection
            </Typography>
            <TableContainer sx={{ width: '100%' }}>
                <Table stickyHeader size='small' >
                    <TableHead>
                        <TableRow >
                            <TableCell sx={cellSx}></TableCell>
                            <TableCell sx={cellSx}>Item</TableCell>
                            <TableCell sx={cellSx}>Value</TableCell>
                            <TableCell sx={cellSx}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {measurementList.map(measurement => (
                            <TableRow hover key={measurement.id}
                                onMouseEnter={() => setHighlightedMeasurement(measurement.id)}
                                onMouseLeave={() => setHighlightedMeasurement(-1)}
                            >
                                <TableCell sx={cellSx}>
                                    <Checkbox
                                        color="primary"
                                        sx={{ p: .1, [`&, &.${checkboxClasses.checked}`]: { color: overlayColorScheme.red, } }}
                                        size='small'
                                        checked={measurement.display}
                                        onChange={() => toggleDisplay(measurement.id)}
                                    />
                                </TableCell>
                                <TableCell sx={cellSx}>
                                    {measurementLabel(measurement.shapeType)}
                                </TableCell>
                                <TableCell sx={cellSx}>
                                    {measurement?.result.toFixed(2)} {unit(measurement.shapeType)}
                                    {measurement?.shapeType === ManualMeasurementOptions.POLYGON && <sup>2</sup>}
                                    {measurement?.shapeType === ManualMeasurementOptions.ANGLE && <sup>o</sup>}
                                </TableCell>

                                <TableCell sx={cellSx}>
                                    {measurement.id === Math.max(...measurementList.map(m => m.id)) && measurementStatus !== MeasurementStatus.COMPLETE ? null : (
                                        <IconButton sx={{ p: 0 }} onClick={() => removeMeasurement(measurement.id)} size='small'>
                                            <ClearIcon fontSize='small' />
                                        </IconButton>
                                    )}
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >
            {
                measurementList.length === 0 &&
                <Typography variant='body2' sx={{ ml: 1, mt: 1, alignSelf: 'center', textAlign: 'center', opacity: 0.7 }} >
                    <i>Toggle the ruler icon on (or press "<code>m</code>") to start measuring. Toggle it off (or press "<code>esc</code>") to stop measuring.</i>
                </Typography>
            }
        </Stack>
    </>
}
