import { CommonSvgShapes } from "../../../types";

export default function MeasurementLayerPolylineSvgs(
    { lines, strokeWidth }: {
        lines: CommonSvgShapes[],
        strokeWidth: string | number,
    }
) {
    return <>
        {
            lines.map(e => (
                <polyline key={e.id}
                    points={e.coordinates.map((point: number[]) => `${point[0]},${point[1]}`).join(" ")}
                    stroke={e.color}
                    strokeWidth={strokeWidth}
                    fill="none"
                />
            ))
        }
    </>
}
