import { TermsOfServiceVersions } from "../pages/terms_of_service/termsLibrary";
import { PageOptions, SliceOrientation, SliceRow } from "../types";

export const appTopNavBarHeight = 64;

// Open a scan in a new tab with the id and if is demo mode
export const newTabLink = (scan_id: number, isDemoMode: boolean) => {
    const baseUrl = process.env.REACT_APP_API_BASEURL as string
    const urlParams = "/?page=slices&public=" + isDemoMode + "&selectedScanId=" + scan_id.toString()
    if (process.env.REACT_APP_API_BASEURL === "http://localhost:5000") {
        return "http://localhost:3000" + urlParams
    }
    return baseUrl + urlParams
}


export const openTermsPageNewTab = () => {
    const baseUrl = process.env.REACT_APP_API_BASEURL as string
    const version = TermsOfServiceVersions.demo_latest
    if (process.env.REACT_APP_API_BASEURL === "http://localhost:5000") {
        return "http://localhost:3000/?page=" + PageOptions.TERMS + "&tos=" + version
    }
    return baseUrl + "/?page=tos&tos=" + version
}

export const minMaxSliderValues = (sliceList: SliceRow[]) => {
    return {
        max: Math.max(...sliceList
            .map((slice: SliceRow) => slice.position)),
        min: Math.min(...sliceList
            .map((slice: SliceRow) => slice.position)),
    }
}

export const middleSlicePosition = (orientation: SliceOrientation, sliceList: SliceRow[]) => {
    const sublist = sliceList.filter((slice: SliceRow) => slice.orientation === orientation)
    const minMax = minMaxSliderValues(sublist)
    const middle = Math.floor((minMax.max + minMax.min) / 2)
    return sublist.reduce((prev, curr) => Math.abs(curr.position - middle) < Math.abs(prev.position - middle) ? curr : prev).position
}


export const formatIsoDate = (date: Date) => {
    return new Date(date).toLocaleString().split(',')[0]
}


export const formatIsoDateTime = (date: Date, useLocalTime?: boolean) => {
    if (useLocalTime) {
        // Convert to local time string, in the timezone of the browser
        const localDateTime = new Date(date).toLocaleString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        });

        // Replace date and time separators to match ISO format
        return localDateTime.replace(',', '').replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1');
    } else {
        // Convert to UTC time string in ISO format, and we strip the timezome data so UTC remains.
        const isoDate = new Date(date).toISOString().split('T')[0];
        const isoTime = new Date(date).toISOString().split('T')[1].split('.')[0];
        return `${isoDate} ${isoTime}`;
    }
}

export const getLocalTimezoneAcronym = () => {
    const date = new Date();
    const formatter = new Intl.DateTimeFormat('en-US', {
        timeZoneName: 'short'
    });

    const formatted = formatter.formatToParts(date);
    const timezonePart = formatted.find(part => part.type === 'timeZoneName');

    return timezonePart ? timezonePart.value : undefined;
}


export const calculateDateDifference = (date1: Date, date2: Date): number => {
    const time1 = new Date(date1).getTime();
    const time2 = new Date(date2).getTime();
    const diff = Math.abs(time1 - time2);
    const diffMinutes = diff / (1000 * 60);
    return parseFloat(diffMinutes.toFixed(2));
};
