import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import LoopIcon from '@mui/icons-material/Loop';
import { Box, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { useAuthTokenAndAccessApi } from '../../auth/authHooks';
import BasicLoadingIndicator from '../../components/BasicLoadingIndicator';
import { UserAccessState } from '../../types';
import { calculateDateDifference, formatIsoDateTime } from '../../utils';
import { ApiEndpoints } from '../../utils/apiUtils';
import { overlayColorScheme } from '../../utils/colorScheme';
import { parentHeaderSx, parentRowSx, tableCellSx } from '../../utils/tableFormats';
import OperatorPageChildTable from './ChildTable';
import DownloadParentTable from './DownloadParentTable';
import {
    LogEntry, ParentTableData, QueueTypes, Queues, ScanStatusOptions,
    VolumeQueueRaw, externalOperatorParentTableRenamer, reduceScanLogs, statusCalculatorFromRawDbLog
} from './utils';


export default function LogOfScans(
    { userAccessState }: { userAccessState: UserAccessState }
) {
    const { fetchData } = useAuthTokenAndAccessApi()
    const [isLoading, setLoadingStatus] = useState(true)
    const [logEntries, setLogEntries] = useState<LogEntry[]>([]);
    const [queueErrorState, setQueueErrorState] = useState(false);
    const [dbErrorState, setDbErrorState] = useState(false);
    const [openRowId, setOpenRowId] = useState(0);
    const [parentTableData, setParentTableData] = useState<ParentTableData[]>([]);
    const [queueRawData, setQueueRawData] = useState<Queues[]>([]);
    const [isLocalTime, setLocalTime] = useState(true); // or UTC
    const isInternal = [UserAccessState.GLIMPSE_INTERNAL_READ, UserAccessState.GLIMPSE_INTERNAL_WRITE].includes(userAccessState);

    useEffect(() => {
        setLoadingStatus(true);
        getLogList();
        // refresh every 20 seconds:
        const intervalId = setInterval(() => { getLogList() }, 20000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLocalTime]);

    const fetchAndProcessQueueData = async () => {
        try {
            const volumeRawQueue = await fetchData(ApiEndpoints.OPERATOR_QUEUES, { queue: QueueTypes.VOLUME_PATHS_READY })
            // TODO: Do we ever want to include the jobs queue here? as of 11/26/2024 we don't. Let's revisit if jobs queue is needed?
            const volumeQueueData = volumeRawQueue.data.map((e: VolumeQueueRaw) => ({
                ...e, queue: QueueTypes.VOLUME_PATHS_READY
            })) as Queues[];

            // TODO this is legacy from when I tried to capture the jobs queue in here too.
            let combinedQueueData = volumeQueueData.map((e, index) => ({ ...e, id: index * -1 - 1 })) as Queues[];

            setQueueRawData(combinedQueueData);
            return combinedQueueData.map((e) => ({
                queueId: e.id,
                scanId: e.scan_id ? e.scan_id : undefined, // Queues don't have scan_id
                status_message: ScanStatusOptions.IN_VOLUME_QUEUE, // now it's just a volumes queue
                serialNumber: e.volume_name,
                batch: "-",
                minCreated: formatIsoDateTime(e.timestamp, isLocalTime),
                maxCreated: formatIsoDateTime(e.timestamp, isLocalTime),
                duration: 0
            })).sort((a: ParentTableData, b: ParentTableData) => (a.queueId ?? -1) < (b.queueId ?? -1) ? -1 : 1) as ParentTableData[];
        } catch (e) {
            setQueueErrorState(true);
            setQueueRawData([]);
            console.error(e);
            return [];
        }
    }

    const fetchAndProcessDbScanLogData = async () => {
        try {
            const dbLogs = await fetchData(ApiEndpoints.OPERATOR_LOGS)
            setLogEntries(dbLogs.data as LogEntry[]);

            const dbLogData: ParentTableData[] = reduceScanLogs(dbLogs.data as LogEntry[]).map((e) => ({
                scanId: e.scanId,
                status_message: statusCalculatorFromRawDbLog(e),
                serialNumber: e.serialNumber,
                batch: e.batchName,
                minCreated: formatIsoDateTime(e.minCreated, isLocalTime),
                maxCreated: formatIsoDateTime(e.maxCreated, isLocalTime),
                duration: calculateDateDifference(e.maxCreated, e.minCreated)
            }))
            return dbLogData;
        } catch (e) {
            setDbErrorState(true);
            setLogEntries([]);
            console.error(e);
            return [];
        }
    }

    const getLogList = async () => {
        // TODO this function is horrible. (update 10/30/24 - it's still horrible)
        //    (update 11/26/24 - it's now a little less horrible in two places)
        const queueDataForTable = await fetchAndProcessQueueData();
        const dbLogData = await fetchAndProcessDbScanLogData();
        setParentTableData(queueDataForTable.concat(dbLogData))
        setLoadingStatus(false);
    }


    const parentTableHeaders = (isInternal: boolean) => {
        if (isInternal) {
            return [
                { key: 'scanId', label: 'Scan ID' },
                { key: 'status_message', label: 'Status' },
                { key: 'serialNumber', label: 'Serial Number' },
                { key: 'batch', label: 'Batch' },
                { key: 'minCreated', label: 'First Log' },
                { key: 'duration', label: 'Duration (minutes)' }
            ] as { key: keyof typeof parentTableData[0], label: string }[]
        } else {
            // What we want to show external users.
            return [
                { key: 'scanId', label: 'Scan ID' },
                { key: 'status_message', label: 'Status' },
                { key: 'serialNumber', label: 'Serial Number' },
                { key: 'minCreated', label: 'First Log' },
                { key: 'duration', label: 'Duration (minutes)' }
            ] as { key: keyof typeof parentTableData[0], label: string }[]
        }
    }


    const parentTableCell = (row: ParentTableData, key: keyof typeof parentTableData[0], isInternalOperator: boolean) => {
        // TODO: if isInternal, we can sanitize this more?
        if (key === "scanId" && (row[key] ?? -1) < 0) return "-"; // scan_id less than 0 is a queue right now. TODO: fix
        if (key === "status_message") {
            let icon = <></>;
            const message = isInternalOperator ? row[key] : externalOperatorParentTableRenamer(row[key] as ScanStatusOptions);
            switch (row.status_message) {
                case ScanStatusOptions.IN_VOLUME_QUEUE:
                case ScanStatusOptions.IN_JOBS_QUEUE:
                    icon = <HourglassEmptyIcon sx={{ color: overlayColorScheme.orange }} />
                    break
                case ScanStatusOptions.POST_PROCESSING_STARTED:
                    icon = <LoopIcon sx={{ color: overlayColorScheme.blue }} />
                    break
                case ScanStatusOptions.POST_PROCESSING_COMPLETED:
                    icon = <CheckCircleIcon sx={{ color: overlayColorScheme.green }} />
                    break
                case ScanStatusOptions.ERROR_IN_PROCESSING:
                    icon = <ErrorIcon sx={{ color: overlayColorScheme.red }} />
                    break
                default: <></>
            }
            return <>
                <Stack direction={'row'} alignItems="center" spacing={1}>
                    {icon}
                    <Typography variant={'body2'}>
                        {message}
                    </Typography>
                </Stack>
            </>
        }
        return row[key]
    }

    const parentTable = () => {
        return <TableContainer >
            <Table size="small">
                <TableHead>
                    <TableRow sx={parentHeaderSx}>
                        <TableCell sx={tableCellSx}></TableCell>
                        {parentTableHeaders(isInternal).map(header => (
                            <TableCell key={header.label} sx={tableCellSx}>
                                {header.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {parentTableData.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={parentTableHeaders(isInternal).length}>No data</TableCell>
                        </TableRow>
                    ) : (
                        parentTableData.map((row, index) => (
                            <React.Fragment key={index}>
                                <TableRow key={index} sx={parentRowSx}>
                                    <TableCell sx={tableCellSx}>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => {
                                                if (openRowId === index) setOpenRowId(-1)
                                                else setOpenRowId(index)
                                            }}
                                        >
                                            {openRowId === index ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </IconButton>
                                    </TableCell>
                                    {parentTableHeaders(isInternal).map(header => (
                                        <TableCell key={header.key} sx={tableCellSx}>
                                            {parentTableCell(row, header.key, isInternal)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                                <OperatorPageChildTable
                                    rowId={index}
                                    queueRawData={queueRawData}
                                    parentTableData={parentTableData}
                                    openRowId={openRowId}
                                    logEntries={logEntries}
                                    isLocalTime={isLocalTime}
                                    isInternalOperator={isInternal}
                                />
                            </React.Fragment>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    }

    if (isLoading) return <BasicLoadingIndicator message="Loading scan logs and queue data..." />;
    return <Box sx={{ maxWidth: 1200, margin: "auto", ml: 4, mr: 4 }}>
        <Grid container sx={{ pt: 3, pb: 2 }}>
            <Grid xs={9}>
                <Typography variant="body1" sx={{ flexGrow: 1 }} >
                    Logs of the last 300 scans, both in the queue for processing and completed.
                </Typography>
                {

                    queueErrorState ?
                        <Typography variant="body2" >
                            Error fetching queue data. Log data may be incomplete.
                        </Typography> : null
                }
                {
                    dbErrorState ?
                        <Typography variant="body2"  >
                            Error fetching scan log data.
                        </Typography> : null
                }
            </Grid>
            <Grid xs={3} >
                <DownloadParentTable tableData={parentTableData} />
                <ToggleButtonGroup
                    size='small'
                    sx={{ height: 30 }}
                >
                    <ToggleButton
                        value={true}
                        selected={isLocalTime}
                        onClick={() => setLocalTime(true)}
                    >
                        Local Time
                    </ToggleButton>
                    <ToggleButton
                        value={false}
                        selected={!isLocalTime}
                        onClick={() => setLocalTime(false)}
                    >
                        UTC Time
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
        </Grid>
        {parentTable()}
    </Box >
}
