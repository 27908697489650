import { Box, ButtonBase, List, ListItem, Typography } from "@mui/material";
import { UserAccessState } from "../../types";
import { appTopNavBarHeight } from "../../utils";
import { portalColorScheme } from "../../utils/colorScheme";

export enum OperatorPageOptions {
    LogOfScans = 'LogOfScans',
    SerialNumberRenamingTool = 'SerialNumberRenamingTool',
}

export default function OperatorNav(
    {
        operatorPageType,
        setOperatorPageType,
        userAccessState
    }: {
        operatorPageType: OperatorPageOptions,
        setOperatorPageType: (operatorPageType: OperatorPageOptions) => void,
        userAccessState: UserAccessState
    }) {

    const pageOptions = () => {
        let base = [{ page: OperatorPageOptions.LogOfScans, label: 'Scan Processing Logs' }]
        const serialNumberOption = [{ page: OperatorPageOptions.SerialNumberRenamingTool, label: 'Add Missing SNs' }]

        // TODO: This is temp to release this feature to just 1027 - drop when we release to other customers:
        if (
            process.env.REACT_APP_AUTH_COGNITO_DOMAIN?.includes("company-1027") ||
            userAccessState === UserAccessState.GLIMPSE_INTERNAL_WRITE
        ) {
            return base.concat(serialNumberOption)
        }
        return base
    }

    return (
        <>
            <Box sx={{
                minWidth: 200,
                maxWidth: 200,
                position: "sticky",
                height: `calc(100vh - ${appTopNavBarHeight}px)`,
                mt: 1
            }}>
                <List>
                    {pageOptions().map(({ page, label }) => (
                        <ListItem
                            key={page}
                            component={ButtonBase}
                            onClick={() => setOperatorPageType(page)}
                        >
                            {operatorPageType === page && (
                                <Box
                                    sx={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: '50%',
                                        backgroundColor: portalColorScheme.lightBlue,
                                        marginRight: 1,
                                    }}
                                />
                            )}
                            <Typography
                                variant="body1"
                                style={{ fontWeight: operatorPageType === page ? '900' : 'normal' }}
                            >
                                {label}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>
    );

}
